/* prism theme adjustments */

.notion-code {
  background-color: rgba(249, 250, 251, 1);
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 0.375rem;
  padding: 1.5em !important;
}

.dark-mode .notion-code {
  background-color: rgba(17, 24, 39, 1);
  border-color: rgba(55, 65, 81, 1);
}
.notion code {
  color: rgba(31, 41, 55, 1);
  border: 0 none !important;
  box-shadow: none !important;
  background: none !important;
  padding: 0 !important;
}
.dark-mode .notion code {
  color: rgba(229, 231, 235, 1);
}

.dark-mode .notion .notion-inline-code {
  background: rgb(71, 76, 80) !important;
  color: #ff4081;
  padding: 0.2em 0.4em !important;
}

.notion .notion-inline-code {
  color: #ff4081;
  background: rgba(127, 122, 107, 0.1) !important;
  padding: 0.2em 0.4em !important;
}

.token.cdata,
.token.doctype,
.token.prolog {
  color: rgba(55, 65, 81, 1);
}
.token.comment {
  color: #5b9b4c;
}
.dark-mode .token.cdata,
.dark-mode .token.doctype,
.dark-mode .token.prolog {
  color: rgba(209, 213, 219, 1);
}
.token.punctuation {
  color: rgba(55, 65, 81, 1);
}
.dark-mode .token.punctuation {
  color: rgba(209, 213, 219, 1);
}
.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: rgba(16, 185, 129, 1);
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
  color: rgba(139, 92, 246, 1);
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
  color: rgba(245, 158, 11, 1);
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: rgba(59, 130, 246, 1);
}
.token.class-name,
.token.function {
  color: rgba(236, 72, 153, 1);
}
.token.important,
.token.regex,
.token.variable {
  color: rgba(245, 158, 11, 1);
}
code[class*='language-'],
pre[class*='language-'] {
  color: rgba(31, 41, 55, 1);
}
.dark-mode code[class*='language-'],
.dark-mode pre[class*='language-'] {
  color: rgba(249, 250, 251, 1);
}
pre::-webkit-scrollbar {
  display: none;
}
pre {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.token.operator,
.token.entity,
.token.url,
.token.variable {
  background: none;
}

pre[class*='language-'] > code {
  border-left: 0 none !important;
  box-shadow: none !important;
  background: none !important;
}

pre[class*='language-']:before,
pre[class*='language-']:after {
  display: none !important;
}
