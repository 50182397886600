* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

body,
html {
  padding: 0;
  margin: 0;
}

body {
  --notion-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', 'Noto Sans', sans-serif;
  font-family: var(--notion-font);
  overflow-x: hidden;
}

.static-tweet blockquote {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.static-tweet-emoji {
  height: 1.2em !important;
  width: 1.2em !important;
}

.searchInput {
  color: var(--fg-color);
  caret-color: var(--fg-color);
}
